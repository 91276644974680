import React from "react"
import Tags from "./Tags"
import Date from "./Date"

const Content = ({ props }) => (
    <article id="main">
    <header>
        <h2>{ props.title }</h2>
        <div>{ props.subtext }</div>
        <Date date={props.date} />

    </header>
    <section className="wrapper style5">
        <div className="inner">
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
        {props.post.frontmatter.tags ? (
            <Tags props={props.post.frontmatter.tags}/>
        ) : ''}
        </div>

    </section>

</article>
)

export default Content;