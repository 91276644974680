import React from "react";

const Tags = ( {props} ) => {
    return(
        <div>
        {props.map((item) => (
            <span className="tag">{item}</span>
        ))}
    </div>
    )

}
    
export default Tags;
