import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Content from "../components/Content"

export default ({ data }) => {
    const post = data.markdownRemark;
    const props = {
        title: post.frontmatter.title,
        subtext: post.frontmatter.subtext,
        date: post.parent.modifiedTime,
        content: post.html,
        post: post,
        tags: [],
    };
    return (
        <Layout fullMenu>
            <Content props={ props } />
        </Layout>
    )
}
export const query = graphql`
query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      tags
      title
      subtext
    }
    parent {
      ... on File {
        id
        name
        modifiedTime
      }
    }
  }
}
`